import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useCallback, useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Heading, NextSection, Subtitle } from "../../components/components"

const History = ({ entries = defaultEntries }) => {
  const data = useStaticQuery(query)
  const [active, setActive] = useState("first-steps")

  const handleNext = useCallback(() => {
    const currentIndex = entries.findIndex(e => e.id === active)
    const nextEntry = entries[currentIndex + 1]
      ? entries[currentIndex + 1]
      : entries[0]

    setActive(nextEntry.id)
  }, [active, setActive])

  const handleChange = useCallback(
    e => {
      setActive(e.id)
    },
    [active, setActive]
  )

  useLayoutEffect(() => {
    if (window) {
      window.location.hash = `#${active}`
    }
  }, [active])
  return (
    <>
      <HistoryWrapper>
        <NavigationWrapper id="history">
          <NavigationList>
            {entries.map(e => {
              return (
                <NavigationEntry
                  key={e.id}
                  role="presentation"
                  isActive={e.id === active}
                  onClick={() => handleChange(e)}
                >
                  <span>
                    <Subtitle color="blue">{e.date}</Subtitle>
                    <Heading tag="h3">{e.title}</Heading>
                  </span>
                </NavigationEntry>
              )
            })}
          </NavigationList>
        </NavigationWrapper>
        {entries.map((e, i) => {
          return (
            <ScrollSection color={e.color} key={e.id} id={e.id}>
              <SectionImage
                alt={data.companies.images[i].name}
                image={getImage(data.companies.images[i].image)}
              />
              <SubtitleWrapper>
                <Heading tag="h2">{e.title}</Heading>
                <Subtitle color="blue">{e.description}</Subtitle>
              </SubtitleWrapper>
            </ScrollSection>
          )
        })}
      </HistoryWrapper>
      <NextSection icon="NextSection" inverted onClick={handleNext} />
    </>
  )
}

const HistoryWrapper = styled.div`
  max-height: 100vh;
  font-weight: 300;
  color: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const SectionImage = styled(GatsbyImage)<any>`
  width: 100%;
  height: 100%;
`

export const query = graphql`
  query {
    companies: allFile(
      filter: { relativeDirectory: { in: "history" } }
      sort: { fields: name }
    ) {
      images: nodes {
        name
        image: childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  }
`

const ScrollSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  height: 100vh;
  font-size: 2rem;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color};
  .gatsby-image-wrapper {
    filter: opacity(0.2);
  }
`

const NavigationWrapper = styled.nav`
  position: fixed;
  height: 100vh;
  padding: 30px;
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  z-index: 2;
`

const NavigationList = styled.ul`
  margin: 0 0 100px 30px;
  list-style: none;
`

const NavigationEntry = styled.li<any>`
  position: relative;
  transition: all 0.3s ease-out;
  margin-bottom: 1em;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    border-left: 2px solid white;
    border-top: 2px solid white;
    height: 250px;
    width: 20px;
    position: absolute;
    left: -30px;
    top: 10px;
  }

  &:first-child:after {
    border-top-left-radius: 4px;
  }
  > span {
    opacity: 0.4;
    display: block;
    padding: 0;
    color: #fff;
    text-decoration: none;
    transition: all 0.15s ease-out;
  }
  > span:hover {
    opacity: 1;
    background-color: transparent;
    padding-left: 1em;
  }
  > span:focus {
    opacity: 1;
    background-color: transparent;
  }

  ${props =>
    props.isActive &&
    css`
      pointer-events: none;
      padding-left: 1em;
      > span {
        opacity: 1;
      }
      &:after {
        width: 35px;
        height: 400px;
        top: 35px;
      }
    `};
`

const SubtitleWrapper = styled.div`
  width: 50%;
  padding: 120px;
  position: absolute;
`

const defaultEntries = [
  {
    id: "first-steps",
    color: "#F64747",
    date: "05/2015",
    title: "Die ersten Schritte",
    description:
      "Die Gründer Christian Birg und Patrick Flöter beginnen als Zwei-Mann-Show mit den ersten Aufträgen im Bereich der Webentwicklung und des Onlinemarketings. Nach kurzer Zeit starten wir zudem mit der Umsetzung eigener Projektideen im Web.",
  },
  {
    id: "the-foundation-of-the-gmbh",
    date: "12/2017",
    color: "#22A7F0",
    title: "Gründung der Incoqnito GmbH ",
    description:
      "Die Incoqnito GmbH ist offiziell gegründet und neue Büroräumlichkeiten werden in Hannover-Kirchrode bezogen. Gemeinsam mit unserem langjährigen Partner, der medsolv GmbH, wird die Entwicklung innovativer Software für den HealthCare Bereich angegangen.",
  },
  {
    id: "first-meet-up",
    color: "#F9690E",
    date: "08/2018",
    title: "Veranstaltung eigener Meet-Ups ",
    description:
      "Das Team wächst weiter und wir entscheiden uns, unser eigenen MeetUp ins Leben zu rufen mit dem Namen „React Hannover“. Hier organisieren wir spannende Talks rund um das React-Framework und natürlich XXL-Pizza für die zahlreichen Anwesenden.",
  },
  {
    id: "fhdw-partnerschaft",
    color: "#9B59B6",
    date: "09/2019",
    title: "Kooperation mit Bildungspartnern",
    description:
      "Um weiterhin auf qualifizierte Teamplayer zu setzen und in die Zukunft zu investieren, entscheiden wir uns dazu, unseren ersten Auszubildenen sowie unseren ersten dualen Studenten in Kooperation mit der Fachhochschule für die Wirtschaft Hannover (FHDW) einzustellen.",
  },
  {
    id: "new-office",
    color: "#03C9A9",
    date: "03/2020",
    title: "Umzug in neue Räumlichkeiten",
    description:
      "Nach einer erfolgreichen Zeit in Kirchrode beziehen wir mit unserem Team neue Räumlichkeiten in Hannover-Linden, dem Puls der Stadt. Gleichzeitig lassen wir uns im Bereich Microsoft Azure sowie Amazon Web Services (AWS) weiter zertifizieren.",
  },
]

export default History
